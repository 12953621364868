<template>
    <div class="pull-left">
        <b-button variant="primary" size="sm" @click="showModal">Мэйл солих</b-button>

        <b-modal ref="my-modal" title="Мэйл солих" hide-footer>
            <loading :active.sync="isLoading" 
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"></loading>
            <b-form @submit.stop.prevent="emailSave">
                <b-form-group label="Шинэ мэйл:" label-for="input-change-email">
                    <b-form-input :type="email" id="input-change-email" v-model="form.new_email" required placeholder="Мэйл"></b-form-input>
                </b-form-group>
                <div class="pull-right">
                    <b-button block type="submit" variant="primary">Хадгалах</b-button>
                </div>
            </b-form>
            
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'Customer.ChangeEmail',
    props: ['customerId', 'customer', 'callback'],
    data: function () {
        return {
            isLoading: false,
            form: {
                id: this.customerId,
                new_email: '',
                type: 'new_email'
            }
        }
    },
    methods: {
        emailSave: function() {
            this.isLoading = true
            this.$data.form.token = this.$store.getters.token,
            this.$data.form.email = this.$store.getters.email,

            this.$http.post(
                this.$config.API_URL + 'CustomerWebService/change_data', 
                { data: JSON.stringify(this.$data.form)},
                { headers: this.$store.getters.httpHeader, emulateJSON: true }
                ).then(
                response => {
                    this.isLoading = false;
                    if(response.body.responseResultType == 'SUCCESS') {
                        this.showToast('Амжилттай', 'Амжилттай хадгалагдлаа', 'success')
                        this.hideModal()
                        this.callback()
                    } else {
                        let _ms = response.body.failureMessages.message;
                        for(var i in _ms) {
                            this.showToast('Анхаар', _ms[i].failureMessage, 'warning')
                        }
                    }
                }, 
                response => {
                    this.isLoading = false;
                    this.showToast('Алдаа', 'Алдаа гарсан байна', 'danger')
                }
            );
        },
        showModal() {
            this.$data.form.new_email = ''
            this.$refs['my-modal'].show()
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        }
    }
}
</script>
