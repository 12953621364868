<template>
    <b-card>

        <loading :active.sync="isLoading" 
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"></loading>

        <b-form-group label="Имейл илгээх">
            <b-form-textarea v-model="message.text" :state="message.text.length < 160" placeholder="Илгээх имейлийг бичнэ үү"></b-form-textarea>
        </b-form-group>
        <b-button variant="info" style="margin-top:16px" class="mr-3" @click="sendEmail(customer)">Илгээх</b-button>
    </b-card>
</template>

<script>

 import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    name: 'Customer.Message',
    props: ['customerId', 'customer', 'callback'],
    components: {
        Treeselect,
    },
    data: function () {
        return {
            isLoading: false,
            message:{
              userID: this.customerId,
              text: ""
            },
            selected_type: null,
            note_types: [],
        }
    },
    methods: {
        sendEmail : function(item){
        this.isLoading = true;
        this.$http.post(
                this.$config.API_URL + "CustomerWebService/send_email",
                this.$data.message,
                { 
                    headers: this.$store.getters.httpHeader, emulateJSON: true }
                )
                .then(response => {
                    this.isLoading = false;
                    this.showToast('Амжилттай', 'Амжилттай илгээлээ', 'success')
                }, response => {
                    this.isLoading = false;
                    this.showToast('Алдаа', 'Алдаа гарсан байна', 'danger')
                });
        }
    }
}
</script>
