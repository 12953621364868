<template>
  <b-row>
    <b-col>
      <div >
    
   <div class="mb-3 clearfix">
      <download-excel
              class = "btn btn-primary pull-right ml-3"
              :fetch   = "fetchData"
              :fields = "download.header"
              :before-generate = "startDownload"
              :before-finish = "finishDownload"
              :name="'Оноо авсан түүх.xls'"
              v-if="items != undefined && items.length > 0">

                <b-spinner small type="grow" v-show="download.loading"></b-spinner> 
                <span v-show="download.loading" class="ml-1">Уншиж байна</span>
                <i class="fa fa-download" v-show="!download.loading"></i> 
                <span v-show="!download.loading" class="ml-1">Excel татах</span>
                
          </download-excel>
          <div class="pull-right">
              <b-form-select v-model="query.per_page" :options="[5, 10, 25, 100]" v-if=" items != undefined && items.length > 0">          
              </b-form-select>
            </div>
   </div>
        <div class="table-responsive" v-if="items != undefined && items.length > 0">
          <b-table 
            id="filter-table"
            stacked="md"

            :hover="true" 
            :bordered="true" 
            :small="true" 
            :fixed="false" 
            responsive="sm" 
            :items="items" 
            :fields="columns"
            no-local-sorting
            >
            
            <template v-slot:cell(index)="data">
              {{ data.index + pagination.from }}
            </template>

            <!-- <template v-slot:cell(scoreCredit)="data">
              {{ data.item.scoreCredit }}
            </template> -->

          </b-table>
            <div slot="footer" class="mb-0 mt-3">
            <nav class="pull-right" v-if="pagination.total > query.per_page"><b-pagination class="mb-0" :total-rows="pagination.total" :per-page="query.per_page" v-model="query.page"/></nav>
            <p class="mb-0">{{ pagination.total }} өгөгдлөөс {{ (pagination.to)?pagination.from:0 }} - {{ pagination.to }} өгөгдөл харагдаж байна.</p>
          </div>
        </div>

        <b-alert variant="warning" v-else show class="mb-0">
          Мэдээлэл байхгүй байна
        </b-alert>

      </div>
    </b-col>
  </b-row>
</template>

<script>


import axios from 'axios';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
export default {  
  name: 'Customers.LoyaltyScoreHistory',
  components: {
    axios,
    datePicker
  },
  inheritAttrs: false,
  props: ['customerId'],
  data: () => {
    return {
      isLoading: false,
      filterShow: false,
      query: {
        id: 0,
        page: 1,
        per_page: 10,
        orderBy: 'id',
        orderDirection: 'ASC',
        columns:'id'
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0
      },
      orderDesc: false,
      items: [],
      columns: [
            { label: '№', key: 'index', class: 'text-center'},
            { label: 'Огноо', key: 'createdDate', class: 'text-right '},
            { label: 'Төрөл', key: 'typeName', class: 'text-right'},
            { label: '+ Оноо', key: 'scoreDebit', class: 'text-right'},
            { label: '- Оноо', key: 'scoreCredit', class: 'text-right'},
            { label: 'Тайлбар', key: 'description', class: 'text-center col-5' }
        ],
      download: {
        loading: false,
        header: {}
      },
    }
  },
  created: function () {
    this.$data.query.id = parseInt(this.customerId);
    this.$data.download.header = {}
    for(let i in this.$data.columns) {
      let _field = this.$data.columns[i]
      this.$data.download.header[_field.label] = _field.key
    }
    this.loadData();
  },
  watch: {
    query: {
      handler (query) {
        this.loadData();
      },
      deep: true
    },
  },
  methods: {
    loadData: function() {
        console.log(this.$data.query)
      this.isLoading = true;
      this.$http.get(this.$config.ADMIN_API_URL + 'Loyalty/LoyaltyWebService/get_score_history', 
        {
          params: this.$data.query,
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        })
        .then(response => {
          console.log(response)
          this.isLoading = false;
          this.$data.items = response.body.data
          this.$data.pagination.total = response.body.total
          this.$data.pagination.from = response.body.from
          this.$data.pagination.to = response.body.to
          console.log(response.body)
          
      }, response => {
          this.isLoading = false;
      });
    },
    sortingChanged(ctx) {
      let _field = ''
      for(let i in this.fields) {
        if(this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key
          break
        }
      }
      this.$data.query.orderDirection = (ctx.sortDesc)?'ASC':'DESC'
    },
    async fetchData(){
      if(this.download.loading) {
        this.download.loading = true;
        const response = await axios.get(this.$config.ADMIN_API_URL + 'Loyalty/LoyaltyWebService/get_score_history',{
          params: {
            page: 1,
            per_page: 0,
            orderDirection: this.$data.query.orderDirection,
            orderBy: this.$data.query.orderBy,
            startAt: this.$data.query.startAt,
            endAt: this.$data.query.endAt,
            columns: this.$data.query.columns,
            id: this.$data.query.id
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        });
        if(response.data.status == 500) {
          this.showToast('Алдаа', 'Алдаа гарсан байна. Дахин оролдоно уу', 'danger')
          this.download.loading = false;
          return []
        } 
        let _result = []
        for(let i in response.data.data) {
            let _item = response.data.data[i]
            _item.index = parseInt(i)+1
            if(_item.transactionType == 'LOAN') {
                _item.amount_loan = _item.amount;
                _item.amount_repayment = '';
            } else {
                _item.amount_repayment = _item.amount;
                _item.amount_loan = ''
                _item.bankName = ''
            }
          _result.push(_item)
        }
        return _result
      }
      return []
    },
    startDownload(){
        this.download.loading = true;
    },
    finishDownload(){
        this.download.loading = false;
    }
  }
}
</script>
