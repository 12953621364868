<template>
  <SainScoreFileList :datas="data" :register="registerNumber" :cus_info="cus_info"></SainScoreFileList>
</template>
    

<script>
import ZMS from "../../../Loan/Cib/ZMS.vue";
import SainScoreFileList from "../../../Loan/Cib/SainScoreFileList.vue";

export default {
  name: "sales-new-list",
  components: {
    ZMS,
    SainScoreFileList
  },
  props: ["registerNumber"],
  data: function() {
    return {
      isLoading: false,
      items: [],
      data: [],
      cus_info: [],
      columns: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Нэр", key: "name" },
        { label: "Үүсгэсэн огноо", key: "createdDate" },
      ],
    };
  },
  watch: {
    registerNumber: {
      handler(registerNumber) {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "CibWebService/get_sain_score_list",
          { data: JSON.stringify({ registerNumber: this.registerNumber }) },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            console.log('pdf')
            console.log(response)
            this.isLoading = false;

            this.$data.data = response.body.list
            console.log('response: ', response)
            // this.$data.items = response.body.list.reverse();

            // let url = response.body.fileUrl
            // console.log('file_url: ' + url)
            // fetch(url)
            // .then(res => res.json())
            // .then(out =>
            // {
            //   this.$data.data = out.inquiry
            //   this.$data.cus_info = out.cust
            //   console.log('Checkout this JSON! ', out)
            //   console.log('test')
            // }
            //   )
            // .catch(err => { throw err });
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
