<template>
  <b-row>
    <b-col cols="12">
      <b-card no-focus>
        <loading :active.sync="isLoadingDistribute" :is-full-page="false" :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"></loading>
        <h6>Хариуцаж байгаа ажилтан</h6>
        <b-list-group v-if="distribute_list.length > 0">
          <b-list-group-item v-for="(distribute, index) in distribute_list" :key="index"
            :variant="(distribute_list.length - 1 == index) ? 'primary' : 'secondary'">
            <b-badge :variant="(distribute_list.length - 1 == index) ? 'primary' : 'secondary'" pill>{{
          distribute.groupId }}</b-badge>
            {{ distribute.userName }} - <small>{{ distribute.createdDate }}</small>

          </b-list-group-item>
        </b-list-group>
        <b-alert variant="warning" show v-else>Хариуцсан ажилтан байхгүй байна </b-alert>

        <div v-if="isShow()">
          <hr>
          <b-form-group>

            <select class="form-control" v-model="distributeId" :disabled="isDisabled()">
              <option :key="index" v-for="(item, index) in employees" :value="item.id">
                @{{ item.firstname }}
              </option>
            </select>
          </b-form-group>
          <b-form-group>
            <b-checkbox v-model="isLocked" :disabled="isDisabled()">Түгжих эсэх</b-checkbox>
            <b-alert show class="mt-2 mb-0">Түгжсэн тохиолдолд хариуцсан ажилтай солигдохгүй</b-alert>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-button variant="success" @click="saveRelatedUser" :disabled="isDisabled()">Хадгалах</b-button>
          </b-form-group>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Customer.Distribute",
  props: ["customerId"],
  data: function () {
    return {
      distribute_list: [],
      noteReload: false,
      isLoadingDistribute: false,
      distributeId: null,
      selectedDistributeId: null,
      isLocked: false,
      id: this.$route.params.id,
      employees: [],
      employees_ids: []
    };
  },

  created() {
    this.loadData();
    this.getDistributeUsers()
  },

  methods: {
    getDistributeUsers: function () {
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/get_distribute_users", {
          params: { id: this.$route.params.id },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            console.log("asasasasassasasasa")
            console.log(response)
            this.isLoadingDistribute = false;
            this.$data.distribute_list = response.body.data
          },
          (response) => {
            this.isLoadingDistribute = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    loadData: function () {
      this.isLoadingDistribute = true;
      console.log(this.$store.getters.httpHeader)


      this.$http
        .get(this.$config.API_URL + "CustomerWebService/get_distribute_user", {
          params: { id: this.$route.params.id },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            console.log(response)
            this.isLoadingDistribute = false;
            this.$data.distributeId = response.body.distributeById;
            this.$data.selectedDistributeId = response.body.distributeById
            this.$data.isLocked = response.body.isLocked;
          },
          (response) => {
            this.isLoadingDistribute = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
      this.$data.noteReload = !this.$data.noteReload;


      this.$http
        .get(this.$config.API_URL + "AdminWebService/get_distribute_operator_list", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.$data.employees = response.body.data
            for (var i in this.$data.employees) {
              this.$data.employees_ids.push(this.$data.employees[i]['id'])
            }
          },
          (response) => { }
        );
    },
    saveRelatedUser: function () {
      this.isLoadingDistribute = true;
      if (this.$data.isLocked && this.$data.distributeId == null) {
        this.showToast("Анхаар", 'Хариуцсан ажилтан сонгоогүй байна', "warning");
        this.isLoadingDistribute = false;
      } else {
        this.$http
          .post(
            this.$config.API_URL + "CustomerWebService/set_distribute_user",
            {
              id: this.$route.params.id,
              user_id: this.$data.distributeId,
              is_locked: this.$data.isLocked
            },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.isLoadingDistribute = false;
              console.log(response)
              if (response.body.responseResultType == "SUCCESS") {
                this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                );
                this.loadData();
              } else {
                let _ms = response.body.failureMessages.message;
                for (var i in _ms) {
                  this.showToast("Анхаар", _ms[i].failureMessage, "warning");
                }
              }
            },
            (response) => {
              this.isLoadingDistribute = false;
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          );
      }
    },

    isDisabled() {
      const roles = ['ADMIN', 'SUPER_ADMIN']
      if (roles.includes(this.$store.getters.user.role.code) || this.$data.selectedDistributeId == this.$store.getters.user.id || this.$data.distribute_list.length == 0) {
        return false
      }
      return true
    },
    isShow() {
      const roles = ['ADMIN', 'SUPER_ADMIN']
      if (roles.includes(this.$store.getters.user.role.code) || this.$data.employees_ids.includes(this.$store.getters.user.id)) {
        return true
      }
      return false
    }

  },
};
</script>