<template>
    <b-row class="mb-0" >
      <b-col class="mb-0">
        <div>
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <div class="mb-0">
            <div
              class="table-responsive mb-0"
              v-if="items != undefined && items.length > 0"
            >
              <b-table
                class="mb-0"
                stacked="md"
                hover
                bordered
                small
                responsive="sm"
                :items="items"
                :fields="columns"
                no-local-sorting
              >
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template v-slot:cell(name)="data">
                  <b-link
                    :href="'http://admin.numur.mn/zms/' + data.item.name"
                    target="_blank"
                    >{{ data.item.name }}</b-link
                  >
                </template>
              </b-table>
            </div>

            <b-alert variant="warning" v-else show class="mb-0">
              Мэдээлэл байхгүй байна
            </b-alert>
          </div>
        </div>
      </b-col>
    </b-row>
</template>

<script>
export default {
  name: "sales-new-list",
  props: ["customerId"],
  data: function() {
    return {
      isLoading: false,
      items: [],
      columns: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Овог", key: "lastname" },
        { label: "Нэр", key: "firstname" },
        { label: "Регистер", key: "registerNumber" },
        { label: "Утасны дугаар", key: "phoneNumber" },
      ],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;
      // //console.log("WAWA : " + this.customerId);
      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/get_emergency",
          { customerId: this.customerId },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            // //console.log("asdfasdf");
            // //console.log(response);
            this.isLoading = false;
            this.$data.items = response.body.list;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
