<template>
    <div class="mb-3">
        <b-button variant="primary" @click="showModal">Од нэмэх</b-button>

        <b-modal ref="my-modal" title="Од нэмэх" hide-footer>
            <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
                :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor"
                :z-index="10000"></loading>
            <b-form @submit.stop.prevent="registerStar">
                <b-form-group label="Хувь:" label-for="input-change-phone">
                    <b-select :options="[0.13, 0.08, 0.05, 0.03, 0.02]" v-model="form.percent">
                    </b-select>
                </b-form-group>
                <b-form-group label="Огноо:" label-for="input-change-phone">
                    <date-picker v-model="form.date" :config="{ format: 'YYYY-MM-DD', useCurrent: false }"
                        placeholder="Огноо"></date-picker>
                </b-form-group>
                <div class="pull-right">
                    <b-button block type="submit" variant="primary">Хадгалах</b-button>
                </div>
            </b-form>

        </b-modal>
    </div>
</template>

<script>


import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
export default {
    name: 'Customer.RegisterStar',
    props: ['customerId', 'callback'],
    components: { datePicker },
    data: function () {
        return {
            isLoading: false,
            form: {
                id: this.customerId,
                percent: 0.13,
                date: ''
            }
        }
    },
    methods: {
        registerStar: function () {
            this.isLoading = true
            this.$data.form.token = this.$store.getters.token,
                this.$data.form.email = this.$store.getters.email,

                this.$http.post(
                    this.$config.API_URL + 'CustomerWebService/register_star',
                    { data: JSON.stringify(this.$data.form) },
                    { headers: this.$store.getters.httpHeader, emulateJSON: true }
                ).then(
                    response => {
                        this.isLoading = false;
                        if (response.body.responseResultType == 'SUCCESS') {
                            this.showToast('Амжилттай', 'Амжилттай хадгалагдлаа', 'success')
                            this.hideModal()
                            this.callback();
                        } else {
                            let _ms = response.body.failureMessages.message;
                            for (var i in _ms) {
                                this.showToast('Анхаар', _ms[i].failureMessage, 'warning')
                            }
                        }
                    },
                    response => {
                        this.isLoading = false;
                        this.showToast('Алдаа', 'Алдаа гарсан байна', 'danger')
                    }
                );
        },
        showModal() {
            this.$data.form = {
                id: this.customerId,
                percent: 0.13,
                date: ''
            }
            this.$refs['my-modal'].show()
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        }
    }
}
</script>