<template>
  <b-row>
    <b-col class="mb-3">
      <div>
        <div class="mb-0">
          <download-excel class="btn btn-primary pull-right ml-3" :fetch="fetchData" :fields="download.header"
            :before-generate="startDownload" :before-finish="finishDownload" :name="'Нэмэлт мэдээлэл.xls'"
            v-if="items != undefined && items.length > 0">

            <b-spinner small type="grow" v-show="download.loading"></b-spinner>
            <span v-show="download.loading" class="ml-1">Уншиж байна</span>
            <i class="fa fa-download" v-show="!download.loading"></i>
            <span v-show="!download.loading" class="ml-1">Excel татах</span>

          </download-excel>

          <b-button variant="primary" class="pull-right" @click="showModal(null)">Шинэ</b-button>
        </div>
      </div>
    </b-col>
    <b-col class="col-md-12">
      <div>
        <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"></loading>
        <div class="mb-0">
          <b-modal ref="my-modal" title="Нэмэлт мэдээлэл" hide-footer>
            <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"></loading>
            <b-form @submit.stop.prevent="save">
              <b-form-group label="Категори сонгох:" label-for="categoryId">
                <b-form-select v-model="form.categoryId" required>

                  <option v-for="option in categories" :value="option.id" required>
                    {{ option.name }}
                  </option>
                  >
                </b-form-select>
              </b-form-group>
              <b-form-group label="Нэмэлт мэдээллийн нэр:" label-for="name">
                <b-form-input v-model="form.name" placeholder=""></b-form-input>
              </b-form-group>
              <b-form-group label="Утга:" label-for="value">
                <b-form-input v-model="form.value" placeholder=""></b-form-input>
              </b-form-group>
              <div class="pull-right">
                <b-button block type="submit" variant="primary">Хадгалах</b-button>
              </div>
            </b-form>
          </b-modal>
        </div>

        <div class="table-responsive mb-0" v-if="items != undefined && items.length > 0">
          <b-table class="mb-0" id="filter-table" stacked="md" @sort-changed="sortingChanged" hover bordered small
            responsive="sm" :items="items" :fields="columns" no-local-sorting>
            <template v-slot:cell(index)="data">
              {{ data.index + pagination.from + 1 }}
            </template>

            <template v-slot:cell(action)="data">
              <b-button variant="primary" @click="showModal(data.item)">Засах</b-button>
            </template>

          </b-table>

          <!-- <div slot="footer" class="mb-0 mt-3">
                <nav class="pull-right" v-if="pagination.total > query.per_page">
                  <b-pagination
                    class="mb-0"
                    :total-rows="pagination.total"
                    :per-page="query.per_page"
                    v-model="query.page"
                  />
                </nav>
                <p class="mb-0">
                  {{ pagination.total }} өгөгдлөөс
                  {{ pagination.to ? pagination.from : 0 }} -
                  {{ pagination.to }} өгөгдөл харагдаж байна.
                </p>
              </div> -->
        </div>

        <b-alert variant="warning" v-else show class="mb-0">
          Мэдээлэл байхгүй байна
        </b-alert>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";

import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  name: "sales-new-list",
  components: { axios, datePicker },
  props: ["customerId"],
  data: function () {
    return {
      isLoading: false,
      filterShow: false,
      selectedSales: null,
      update: false,
      query: {
        id: 0,
        page: 1,
        per_page: 10,
        orderDirection: "ASC",
        orderBy: "number",
        filter: "{}",
      },
      filter: {
        customerId: this.customerId,
        search: "",
        startDate: "",
        endDate: "",
        sysUserId: null,
        confirmedUserId: null,
        typeId: 1,
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0,
      },
      orderDesc: false,
      items: [],
      types: [],
      sysUsers: [],
      search: "",
      download: {
        loading: false,
        header: {},
      },
      columns: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Төрөл",
          key: "categoryName",
          sortable: true,
          sort_key: "number",
        },
        {
          label: "Нэр",
          key: "name",
          sortable: true,
          sort_key: "createdDate",
        },
        {
          label: "Утга",
          key: "value",
          sortable: true,
          sort_key: "createdDate",
        },
        {
          label: "Бүргэсэн огноо",
          key: "createdDate",
          sortable: true,
          sort_key: "createdDate",
        },
        {
          label: "Бүргэсэн ажилтан",
          key: "sysUserEmail",
          sortable: true,
          sort_key: "sysUser",
        },
        {
          label: "Үйлдэл",
          key: "action",
        },


      ],
      form: {
        customerId: this.customerId,
        categoryId: null,
        infoId: null,
        name: "",
        value: "",
        createdBy: this.$store.getters.user.id,
        token: this.$store.getters.token,
        email: this.$store.getters.email
      },
      categoryId: "",
      categories: [],
      download: {
        loading: false,
        header: {
          '№': 'index',
          'Төрөл': 'categoryName',
          'Нэр': 'name',
          'Утга': 'value',
          'Бүргэсэн огноо': 'createdDate',
          'Бүргэсэн ажилтан': 'sysUserEmail',
        }
      },
    };
  },
  watch: {
    query: {
      handler(query) {
        this.loadData();
      },
      deep: true,
    },
  },
  created: function () {
    this.initData();
    this.$data.query.filter = JSON.stringify(this.$data.filter);
    this.$data.page = 1;
    this.loadData();
  },
  methods: {
    initData: function () {
      // this.$http.get(this.$config.API_URL + 'StatementWebService/get_statement_type_list',
      //     {
      //         headers: this.$store.getters.httpHeader,
      //         emulateJSON: true
      //     })
      // .then(response => {
      //     //console.log(response)
      //     this.$data.types = response.body.data
      // }, response => {

      // });

      this.$http
        .get(this.$config.API_URL + "ReferenceWebService/get_category_list", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.$data.categories = response.body;
          },
          (response) => { }
        );
    },
    loadData: function () {
      this.checkSession();
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/get_extra_info_list", {
          params: { id: this.customerId },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.items = response.body.list;
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    sortingChanged(ctx) {
      let _field = "";
      for (let i in this.fields) {
        if (this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key;
          break;
        }
      }
      this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
      this.loadData();
    },
    submitSearch: function () {
      // this.$router.replace({ name: this.$route.name, query: this.$data.query})
      this.$data.query.filter = JSON.stringify(this.$data.filter);
      this.$data.page = 1;
      this.loadData();
    },

    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    },

    showModal(data) {
      this.$data.update = false
      console.log('hello', this.$store.getters.user)
      this.$data.form.name = "";
      this.$data.form.value = "";
      this.$data.form.categoryId = null;
      if (data) {
        this.$data.update = true
        this.$data.form.name = data.name
        this.$data.form.value = data.value
        this.$data.form.infoId = data.id
        for (let i in this.$data.categories) {
          if (this.$data.categories[i].name == data.categoryName) {
            this.$data.form.categoryId = this.$data.categories[i].id
          }
        }
      }

      this.$refs["my-modal"].show();
    },
    hideModal() {
      console.log('world')
      this.$refs["my-modal"].hide();
    },

    save: function () {
      if (!this.$data.update) {
        this.isLoading = true;
        this.$http
          .post(
            this.$config.API_URL + "CustomerWebService/create_extra_info",
            { data: JSON.stringify(this.$data.form) },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.isLoading = false;
              // //console.log(response)
              if (response.body.responseResultType == "SUCCESS") {
                this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                );
                this.hideModal()
                this.loadData()
              } else {
                this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
              }
            },
            (response) => {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          );
      } else {
        this.isLoading = true;
        this.$http
          .post(
            this.$config.API_URL + "CustomerWebService/update_extra_info",
            { data: JSON.stringify(this.$data.form) },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.isLoading = false;
              // //console.log(response)
              if (response.body.responseResultType == "SUCCESS") {
                this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                );
                this.hideModal()
                this.loadData()
              } else {
                this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
              }
            },
            (response) => {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          );
      }
    },
    async fetchData() {

      if (this.download.loading) {
        this.download.loading = true;
        const response = await axios.get(this.$config.API_URL + 'CustomerWebService/get_extra_info_list', {
          params: {
            id: this.customerId
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        });
        console.log(response)
        if (response.data.status == 500) {
          this.showToast('Алдаа', 'Алдаа гарсан байна. Дахин оролдоно уу', 'danger')
          this.download.loading = false;
          return []
        }
        let _result = []
        for (let i in response.data.list) {
          let _item = response.data.list[i]
          _item.index = parseInt(i) + 1
          _result.push(_item)
        }
        return _result
      }
      return []
    },
    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    }
  },
};
</script>