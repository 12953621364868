<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
      :width="30"
      :height="30"
    ></loading>
    <b-alert variant="warning" v-show="json == null" show class="mb-0"
      >Мэдээлэл байхгүй байна</b-alert
    >
    <b-alert show>
      {{ customer.xypUpdatedDate }} <b-badge>{{ customer.xypUpdatedEmail }}</b-badge>
    </b-alert>
    <tree-view
      :data="json"
      :options="{ maxDepth: 2, rootObjectKey: 'ХУР' }"
    ></tree-view>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Customers.xypInfo",
  props: ["customer"],
  data: function() {
    return {
      json: {},
      isLoading: false,
    };
  },
  watch: {
    customer: {
      handler(customer) {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;
      this.$http
        .post(
            this.$config.NUMUR_MS_ADMIN_URL + "main/get-xyp",
            { person_id: this.customer.personId },
          // {
          //   params: { id: this.customer.personId },
          //   headers: this.$store.getters.httpHeader,
          //   emulateJSON: true,
          // }
        )
        .then(
          (response) => {
            console.log(response);
            this.isLoading = false;
            if (response.body && response.body.success) {
              this.$data.json = response.body.data
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
