<template>
    <b-row>
        <b-col>
            <div v-if="fbData && fbData.hasOwnProperty('fbName')">
                <b-img alt="placeholder" :src="fbData.fbImage"></b-img>
                <h2 class="mt-3">{{ fbData.fbName }}</h2>
            </div>
            <b-alert variant="warning" v-else show class="mb-0">
                Facebook холбоогүй байна
            </b-alert>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'Customer.Facebook',
    props: ['customerId'],
    data: function () {
        return {
            fbData: null
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData: async function() {
            var response = await this.$http.get(this.$config.API_URL + 'CustomerWebService/get_facebook_data', 
            {
                params: {
                    customer_id: this.customerId
                },
                headers: this.$store.getters.httpHeader,
                emulateJSON: true
            });

            this.$data.fbData = JSON.parse(response.data.successData);
        }
    }
}
</script>
