<template>
  <b-row>
    <b-col cols="12">
      <b-card v-if="customer">
        <h3 class="mb-0">{{ customer.currentLoyaltyGroupName }} <small class="bg-info p-1 pull-right rounded">{{
        customer.currentScore }} оноо</small></h3>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Customer.LoyaltyId",
  props: ["customerId"],
  data: function () {
    return {
      isLoading: false,
      customer: null
    };
  },

  created() {
    this.loadData();
  },

  methods: {

    loadData() {
      this.isLoading = true;
      this.$http
        .get(this.$config.ADMIN_API_URL + "Loyalty/LoyaltyWebService/get_loyalty_info", {
          params: { id: this.customerId },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        // .get(this.$config.TEST_NUMUR_MS_ADMIN_URL + "customer/get_loyalty_info",
        //     this.customerId,
        //     {
        //       headers: {
        //         ...this.$store.getters.httpHeader, // Include your custom headers
        //         "Content-Type":"application/json", // Ensure JSON content type
        //       }
        //     })
        .then(
          (response) => {
            this.isLoading = false;
            console.log(response)
            this.$data.customer = response.body;
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
  },
};
</script>