<template>
    <div>
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      
        <b-alert
            show
            variant="info"
            class="mt-0 mb-3"
        >
            Утасны дугаар хамгийн сүүлд солигдсон огноо: <strong>{{ result }}</strong>
        </b-alert>

        
    
      </div>
  </template>
  
  <script>
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  
  export default {
    name: "Customer.PayRequest",
    props: ["customerId", "customer", "callback"],
    components: {
      Treeselect,
    },
    data: function() {
      return {
        isLoading: false,
        selected_type: false,
        result: "",
        resultType: "text-primary",
      };
    },

    created() {
        this.getPhoneLog()
    },
    methods: {
      getPhoneLog: function(is_new) {
        this.isLoading = true;
        this.$data.resultType = "text-primary";
        this.$http
          .post(
            this.$config.API_URL + "CustomerWebService/get_user_phone_log",
            {
              id: this.customerId,
            },
            {
              headers: this.$store.getters.httpHeader,
              emulateJSON: true,
            }
          )
          .then(
            (response) => {
              // console.log("asdfasdfasdfhaskldfhaskdhfakl jdaskljdfh asklhf");
              console.log(response);
              if (response.body.responseResultType == "SUCCESS") {
                this.$data.resultType = "text-primary";
                this.$data.result = response.body.successData;
              } else {
                this.$data.resultType = "text-danger";
                this.$data.result = "Дугаар одоогоор солигдоогүй байна";
              }
              this.isLoading = false;
            },
            (response) => {
              this.isLoading = false;
              this.$data.resultType = "text-danger";
              this.$data.result = "Алдаа гарсан байна";
            }
          );
      },
    },
  };
  </script>
  