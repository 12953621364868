<template>
  <b-card>
    <b-row>
      <b-col>
        <div>
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <div class="mb-3">
            <div
              class="table-responsive"
              v-if="items != undefined && items.length > 0"
            >
              <b-table
                stacked="md"
                hover
                bordered
                small
                responsive="sm"
                :items="items"
                :fields="columns"
                no-local-sorting
              >
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template v-slot:cell(name)="data">
                  <b-link
                    :href="'http://kiosk.numur.mn/contracts/' + data.item.name"
                    target="_blank"
                    >{{ data.item.name }}</b-link
                  >
                </template>
              </b-table>
            </div>

            <b-alert variant="warning" v-else show class="mb-0">
              Мэдээлэл байхгүй байна
            </b-alert>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "sales-new-list",
  props: ["registerNumber"],
  data: function() {
    return {
      isLoading: false,
      items: [],
      columns: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Нэр", key: "name" },
      ],
    };
  },
  watch: {
    registerNumber: {
      handler(registerNumber) {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;
      console.log(this.registerNumber)
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/get_kiosk_contract", {
            params: { registerNumber: this.registerNumber }
          },
          {
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            console.log(response)
            this.isLoading = false;
            this.$data.items = response.body.list.reverse();
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
