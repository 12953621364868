<template>
  <b-card>
    <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
    </loading>
    <div>
      <h3 class="pull-left">
        Эргэн төлөлтийн хуваарь
      </h3>
      <b-alert variant="secondary" show class="pull-right">
        <h5 class="mb-0  text-right">
          <table>
            <tr v-if="overpayment > 0" class="text-primary">
              <td class="pr-3">Илүү төлөлт:</td>
              <td>
                <strong>{{ overpayment | currecryZero }}₮</strong>
              </td>
            </tr>
            <tr v-if="underpayment > 0" class="text-info">
              <td class="pr-3">Дутуу төлөлт:</td>
              <td>
                <strong>{{ underpayment | currecryZero }}₮</strong>
              </td>
            </tr>
            <tr v-if="fee > 0" class="text-danger">
              <td class="pr-3">Торгууль:</td>
              <td>
                <strong>{{ fee | currecryZero }}₮</strong>
              </td>
            </tr>
          </table>
        </h5>
      </b-alert>
    </div>
    <b-table v-if="schedule_show" class="mt-3 align-middle" hover :items="schedule" :fields="[
      { label: '№', key: 'index', class: 'text-center' },
      { label: 'Төлөлт хийх огноо', key: 'payment_date' },
      {
        label: 'Төлөлт хийх дүн',
        key: 'payment_amount',
        class: 'text-right',
      },
      { label: 'Үйлдэл', key: 'action', class: 'text-center' },
      { label: '', key: 'pay', class: 'text-center' },
    ]">
      <template v-slot:cell(HEAD_index)="data">
        <h6 class="mb-0">{{ data.label }}</h6>
      </template>
      <template v-slot:cell(HEAD_payment_date)="data">
        <h6 class="mb-0">{{ data.label }}</h6>
      </template>
      <template v-slot:cell(HEAD_payment_amount)="data">
        <h6 class="mb-0">{{ data.label }}</h6>
      </template>
      <template v-slot:cell(HEAD_action)="data">
        <h6 class="mb-0">{{ data.label }}</h6>
      </template>

      <template v-slot:cell(index)="data">
        <h5 class="mb-0">{{ data.index + 1 }}</h5>
      </template>
      <template v-slot:cell(payment_date)="data">
        <h5 class="mb-0">{{ data.item.paymentDate }}</h5>
      </template>
      <template v-slot:cell(payment_amount)="data">
        <h5 class="mb-0 text-primary">
          {{ data.item.showpayment }}₮ /
          <small class="text-muted">{{ data.item.amount }}₮</small>
        </h5>
      </template>
      <template v-slot:cell(action)="data">
        <b-button size="sm" @click="data.toggleDetails" class="mr-2">
          <i :class="{
      'fa fa-chevron-right': !data.detailsShowing,
      'fa fa-chevron-down': data.detailsShowing,
    }"></i>
        </b-button>
      </template>
      <template v-slot:cell(pay)="data">
        <b-button variant="primary" size="sm" v-if="data.item.overpayment &&
      parseFloat(data.item.overpayment.replace(/,/g, '')) > 0 &&
      today >= data.item.paymentDate &&
      checkPermission('admin.overpayment.to_repayment')
      " @click="overpayementToRepayment(true)">төлөх
        </b-button>
      </template>

      <template v-slot:row-details="data">
        <ul class="list-group" style="margin: -13px;">
          <li
            class="border-left-0 border-right-0 rounded-0 bg-gray-100 list-group-item d-flex justify-content-between align-items-center"
            :key="index" v-for="(detail_data, index) in data.item.detail">
            <h5 class="mb-0 ml-2">
              #{{ detail_data.accountNumber }}<br />
              <small>{{ detail_data.loanDate }} </small>
            </h5>
            <div class="text-right mr-4">
              <h5 class="mb-0">
                <small>{{ detail_data.loanAmount }} ₮</small><br />
                <span class="text-primary">{{ detail_data.amount }} ₮</span>
              </h5>
            </div>
          </li>
          <li
            class="border-left-0 border-right-0 rounded-0 bg-gray-100 list-group-item d-flex justify-content-between align-items-center"
            v-if="data.item.overpayment &&
      parseFloat(data.item.overpayment.replace(/,/g, '')) > 0
      ">
            <h5 class="mb-0 ml-2">
              Илүү төлөлт
            </h5>
            <div class="text-right mr-4">
              <h5 class="mb-0">
                <span class="text-primary">{{ data.item.overpayment }} ₮</span>
              </h5>
            </div>
          </li>
          <li
            class="border-left-0 border-right-0 rounded-0 bg-gray-100 list-group-item d-flex justify-content-between align-items-center"
            v-if="data.item.underpayment &&
      parseFloat(data.item.underpayment.replace(/,/g, '')) > 0
      ">
            <h5 class="mb-0 ml-2">
              Дутуу төлөлт
            </h5>
            <div class="text-right mr-4">
              <h5 class="mb-0">
                <span class="text-primary">{{ data.item.underpayment }} ₮</span>
              </h5>
            </div>
          </li>
          <li
            class="border-left-0 border-right-0 rounded-0 bg-gray-100 list-group-item d-flex justify-content-between align-items-center"
            v-if="data.item.fee && parseFloat(data.item.fee.replace(/,/g, '')) > 0
      ">
            <h5 class="mb-0 ml-2">
              Шимтгэл
            </h5>
            <div class="text-right mr-4">
              <h5 class="mb-0">
                <span class="text-primary">{{ data.item.fee }} ₮</span>
              </h5>
            </div>
          </li>
        </ul>
      </template>
    </b-table>
    <table v-else class="mt-5">
      <tbody>
        <tr>
          <td>
            <b-alert variant="warning" show class="mb-0">
              Төлбөрийн хуваарь байхгүй байна
            </b-alert>
          </td>
        </tr>
      </tbody>
    </table>
  </b-card>
</template>

<script>
import moment from "moment";

export default {
  name: "Customers.repaymentSchedule",
  props: ["customerId"],
  data: function () {
    return {
      schedule: [],
      isLoading: false,
      today: moment().format("YYYY-MM-DD"),
      overpayment: 0,
      underpayment: 0,
      fee: 0,
      schedule_show: false
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData: function () {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/get_repayment_schedule",
          {
            params: { id: this.customerId },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.schedule = response.body.data;
            for (let i in this.$data.schedule) {
              let item = this.$data.schedule[i];
              if (item.hasOwnProperty('id')) {
                this.$data.schedule_show = true
              }
              if (item.overpayment) {
                this.$data.overpayment = parseFloat(
                  item.overpayment.replace(/,/g, "")
                );
              }

              if (item.underpayment) {
                this.$data.underpayment = parseFloat(
                  item.underpayment.replace(/,/g, "")
                );
              }

              if (item.fee) {
                this.$data.fee = parseFloat(item.fee.replace(/,/g, ""));
              }
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    overpayementToRepayment: function (is_small) {
      this.isLoading = true;

      // //console.log(JSON.stringify({
      //     token: this.$store.getters.token,
      //     email: this.$store.getters.email,
      //     customerId: this.$route.params.id,
      //     isSmall: is_small ? 1: 0
      // }))

      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/overpayment_to_repayment",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              customerId: this.$route.params.id,
              isSmall: is_small,
            }),
          },
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
        )
        .then(
          (response) => {
            this.isLoading = false;
            if (response.body.responseResultType == "SUCCESS") {
              this.showToast(
                "Амжилттай",
                "Төлбөрийн амжилттай бүртгэлээ",
                "success"
              );
              this.loadData();
            } else {
              this.showToast(
                "Алдаа",
                "Алдаа гарсан байна. Дахин оролдоно уу",
                "danger"
              );
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast(
              "Алдаа",
              "Алдаа гарсан байна. Дахин оролдоно уу",
              "danger"
            );
          }
        );
    },
  },
};
</script>