<template>
    <div class="pull-left">
        <download-excel
                class = "btn btn-primary btn-sm pull-right"
                :fields = "header"
                :fetch   = "fetchData"
                :before-generate = "startDownload"
                :before-finish = "finishDownload"
                :name="getFullname + ' мэдээлэл өөрчилсөн тайлан ' + today + '.xls'"
                :worksheet="getStartDate + ' - ' + getEndDate"
                >
                <b-spinner small type="grow" v-show="download.loading"></b-spinner> 
                <span v-show="download.loading" class="ml-1">Уншиж байна</span>
                <i class="fa fa-download" v-show="!download.loading"></i> 
                <span v-show="!download.loading" class="ml-1">Мэдээлэл өөрчилсөн түүх</span>
                
                
            </download-excel>        
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
    name: 'Customer.CheckStar',
    components: { axios },
    props: ['customerId', 'customer', 'callback'],
    data: function () {
        return {
            download: {
                loading: false,
            },
            today: moment().format('YYYY-MM-DD'),
            header: {
                '#': 'index',
                'Овог': '0',
                'Нэр': '1',
                'РД': '2',
                'Төрөл': '3',
                'Хуучин утга': '4',
                'Шинэ утга': '5',
                'Тайлбар': '6',
                'Огноо': '7',
                'Ажилтан': '8',
              }
        }
    },
    computed: {
        getStartDate() { return moment('2019-01-01').format('YYYY-MM-DD') },
        getEndDate() { return moment().format('YYYY-MM-DD') },
        getFullname() { return this.customer.firstname }
    },
    methods: {
        async fetchData(){
        if(this.download.loading) {
            this.download.loading = true;
            const response = await axios.get(this.$config.API_URL + 'ReportWebService/get_list_audit',{
            params: {
                start_at: this.getStartDate,
                end_at: this.getEndDate,
                type: 'info_change',
                cusIds: '[' + this.customerId + ']'
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true
            });
            if(response.data.status == 500) {
            showToast('Алдаа', 'Алдаа гарсан байна. Дахин оролдоно уу', 'danger')
            this.$data.download.loading = false;
            return []
            } 
            let _result = []
            for(let i in response.data.data) {
            let _item = response.data.data[i]
            _item.index = parseInt(i)+1
            _result.push(_item)
            }
            return _result
        }
        return []
        },
        startDownload(){
            this.download.loading = true
        },
        finishDownload(){
            this.download.loading = false
        }
    }
}
</script>
