<template>
  <div class="pull-left">
    <b-button variant="success" size="sm" @click="checkStar()">
      <b-spinner small type="grow" v-show="isLoading"></b-spinner>
      Од олгох /шинэ/
    </b-button>
  </div>
</template>

<script>
export default {
  name: "Customer.CheckStar",
  props: ["customerId", "customer", "callback"],
  data: function () {
    return {
      isLoading: false,
      form: { customer_id: this.customerId },
    };
  },
  methods: {
    checkStar: function () {
      this.isLoading = true;

      this.$http
        .get(this.$config.API_URL + "CustomerWebService/check_stars_new", {
          params: this.$data.form,
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            // //console.log(response)
            if (response.body.responseResultType == "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай олголоо", "success");
              this.callback();
            } else {
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
  },
};
</script>