<template>
  <div class="pull-left">
    <b-button variant="danger" size="sm" @click="showModal" v-if="customer.isIncomeInfo">Зээлийн эрх хаах</b-button>
    <b-button variant="success" size="sm" @click="showModal" v-else>Зээлийн эрх нээх</b-button>

    <b-modal ref="my-modal" :title="'Зээлийн эрх ' + (customer.isIncomeInfo ? 'хаах' : 'нээх')" hide-footer>
      <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
      </loading>
      <p class="text-danger" v-if="customer.isIncomeInfo">
        Та хэрэглэгчийн зээл авах эрхийг хаахдаа итгэлтэй байна уу?
      </p>
      <p class="text-danger" v-else>
        Та хэрэглэгчийн зээл авах эрхийг нээхдээ итгэлтэй байна уу?
      </p>
      <b-form @submit.stop.prevent="phoneSave">
        <b-button class="pull-right" type="button" @click="hideModal" variant="danger">Болих</b-button>
        <b-button class="pull-right mr-3" type="submit" variant="primary">Тийм</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Customer.ChangeLoanActive",
  props: ["customerId", "customer", "callback"],
  data: function () {
    return {
      isLoading: false,
      form: { id: this.customerId },
    };
  },
  methods: {
    phoneSave: function () {
      this.isLoading = true;
      (this.$data.form.token = this.$store.getters.token),
        (this.$data.form.email = this.$store.getters.email);
      if (this.customer.isIncomeInfo) {
        this.haah()
      } else {
        this.neeh()
      }
    },
    neeh: function () {
      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/set_income_info",
          this.$data.form,
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
        )
        .then(
          (response) => {
            this.isLoading = false;
            console.log(response)
            if (response.body.responseResultType == "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
            } else {
              if (response.body.hasOwnProperty('failureMessages')) {
                let _ms = response.body.failureMessages.message;
                for (var i in _ms) {
                  this.showToast("Анхаар", _ms[i].failureMessage, "warning");
                }
              } else {
                this.showToast("Анхаар", "Алдаа гарсан байна", "warning");
              }
            }
            this.hideModal();
            this.callback();
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    haah: function () {
      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/set_change_loan_active",
          { data: JSON.stringify(this.$data.form) },
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
        )
        .then(
          (response) => {
            this.isLoading = false;
            // //console.log(response)
            if (response.body.responseResultType == "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");

            } else {
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
            this.hideModal();
            this.callback();

          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    showModal() {
      this.$data.form.new_phone = "";
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>