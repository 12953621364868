<template>
  <b-row>

    <b-col cols="8">
      <b-card v-if="customer">
        <h3 class="mb-0">{{ customer.currentLoyaltyGroupName }} <small class="bg-info p-1 pull-right rounded">{{
        customer.currentScore }} оноо</small></h3>
      </b-card>
      <LoyaltyScoreHistory :customerId="customerId"> </LoyaltyScoreHistory>
    </b-col>
  </b-row>
</template>

<script>
import LoyaltyScoreHistory from "./LoyaltyScoreHistory";
import RegisterStar from "./RegisterStar";
import RegisterStarNew from "./RegisterStarNew";
export default {
  name: "Customer.Star",
  props: ["customerId"],
  components: { RegisterStar, LoyaltyScoreHistory },
  data: function () {
    return {
      refreshState: false,
      refreshStateStar: false,
      customer: {

      },
      columns_star: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Хувь",
          key: "percentage",
          sortable: true,
          sort_key: "percent",
        },
        {
          label: "Дүн",
          key: "amount",
          sortable: true,
          sort_key: "amount",
          class: "text-right",
        },
        // { label: 'Дугаар', key: 'number' },
        {
          label: "Ашигласан эсэх",
          key: "isComplete",
          sortable: true,
          sort_key: "is_complete",
          class: "text-center",
        },
        {
          label: "Ашигласан огноо",
          key: "completeDate",
          sortable: true,
          sort_key: "complete_date",
        },
        {
          label: "Од авсан огноо",
          key: "createdDate",
          sortable: true,
          sort_key: "created_date",
        },
      ],
      columns_increase: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Өмнөх зээлийн эрх",
          key: "prevAmount",
          sortable: true,
          sort_key: "prev_amount",
          class: "text-right",
        },
        {
          label: "Нэмэгдсэн дүн",
          key: "amount",
          sortable: true,
          sort_key: "amount",
          class: "text-right",
        },
        {
          label: "Бодит дүн",
          key: "realAmount",
          sortable: true,
          sort_key: "real_amount",
          class: "text-right",
        },
        {
          label: "Эрх нэмсэн огноо",
          key: "createdDate",
          sortable: true,
          sort_key: "created_date",
        },
        // { label: 'Од авсан огноо', key: 'createdDate', sortable: true, sort_key:'created_date' }
      ],
    };
  },

  created() {
    this.loadData();
  },

  methods: {
    reload: function () {
      this.$data.refreshState = !this.$data.refreshState;
    },

    loadData() {
      this.isLoading = true;
      this.$http
        .get(this.$config.ADMIN_API_URL + "Loyalty/LoyaltyWebService/get_loyalty_info", {
          params: { id: this.customerId },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.customer = response.body;
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
      this.$data.noteReload = !this.$data.noteReload;
    },

    loadDataHistory() {
      this.isLoading = true;
      this.$http
        .get(this.$config.ADMIN_API_URL + "Loyalty/LoyaltyWebService/get_score_history", {
          params: {
            id: this.customerId,
            page: 1,
            per_page: 5,
            orderBy: 'id',
            orderDirection: 'ASC',
            columns: 'id'
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        //   .post(this.$config.NUMUR_MS_ADMIN_URL + "customer/get_score_history", {
        //      id: this.customerId,
        //               page: 1,
        //               per_page: 5,
        //               orderBy: 'id',
        //               orderDirection: 'ASC',
        //               columns: 'id'},
        //       {
        //         headers: {
        //           ...this.$store.getters.httpHeader, // Include your custom headers
        //           "Content-Type":"application/json", // Ensure JSON content type
        //         }
        //   })
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.score_history = response.body;
            console.log(response.body)
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
      this.$data.noteReload = !this.$data.noteReload;
    }
  },
};
</script>